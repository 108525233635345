import styled from 'styled-components'

const StyledPage = styled.div`
  width: 328px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  @media screen and (min-width: 430px) {
    width: 370px;
  }
  .imgbox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    color:#fff;
    margin-left:-6px;
    .footerlogo {
      width: 54px;
      height: 22px;
      margin: 0 auto;
    }
  }
  .footer-text {
    font-family: Alibaba;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    margin: 24px auto;
  }
  .footer-icon-box {
    display: flex;
    margin: 0 auto;
    .footer-icon {
      width: 24px;
      height: 24px;
      margin-left: 25px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
`

const handleImageClick = (imageUrl) => {
  console.log('Clicked image URL:', imageUrl)
  if (imageUrl == 'tg') {
    window.open('https://s65535.com/pizzacoinbsc')
  } else if (imageUrl == 'x') {
    window.open('https://x.com/PizzaCoinBsc')
  }
}

const IndexFooter = () => {

  return (
    <StyledPage>
      <div className="imgbox" >
        {/* PIZZA */}
        <img  className="footerlogo" src="/images/index/foot-logo.png" />
      </div>
      <div className="footer-text" >
        关于 / 服务 / 博客
      </div>
      <div className="footer-icon-box">
        <img className="footer-icon" onClick={() => handleImageClick('tg')} src="/images/index/tg.png" />
        <img className="footer-icon" onClick={() => handleImageClick('x')} src="/images/index/x.png" />
        <img className="footer-icon" onClick={() => handleImageClick('face')} src="/images/index/face.png" />
        <img className="footer-icon" onClick={() => handleImageClick('youtube')} src="/images/index/youtube.png" />
      </div>
    </StyledPage>
  )
}

export default IndexFooter
