import styled from 'styled-components'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/autoplay'
const StyledSwiper = styled.div`
  width: 328px;
  height: 120px;
  @media screen and (min-width: 430px) {
    width: 370px;
  }
  .swiper-box {
    width: 328px;
    height:120px;
    @media screen and (min-width: 430px) {
      width: 370px;
    }
    .swiper-img{
      width:100%;
      height:100%;
    }
  }
`

const IndexAd = () => {
  return (
    <StyledSwiper>
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        className='swiper-box'
        // style={{ width: '328px', height: '120px' }}
        slidesPerView={1}
      >
        <SwiperSlide>
          <img src="/images/1-2.png" className='swiper-img'  alt="AD广告位"></img>
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/2-1.png" className='swiper-img' alt="AD广告位"></img>
        </SwiperSlide>
      </Swiper>
    </StyledSwiper>
  )
}
export default IndexAd
